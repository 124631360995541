<template>
  <section class="bando-head">
    <h1>Prendre un rendez-vous</h1>

    <!-- Class "active" sur step en cours -->
    <ul class="steps-rdv">
      <li>
        <span class="num" :class="{ active: step == 1 }">1</span> Bénéficiaire
      </li>
      <li>
        <span class="num" :class="{ active: step == 2 }">2</span> Planifier le
        rendez-vous
      </li>
      <li>
        <span class="num" :class="{ active: step == 3 }">3</span> Validation
      </li>
    </ul>
  </section>

  <div class="container">
    <template v-if="step == 1">
      <!-- STEP 1 / FORMULAIRE -->
      <RdvStep1
        @next="
          step = 2;
          beneficiaire = $event;
        "
        v-bind:beneficiairev="beneficiaire"
      />
    </template>

    <template v-if="step == 2">
      <!-- STEP 2 / AGENDA -->
      <RdvStep2
        @previous="step = 1"
        @next2="
          step = 3;
          rdvDate = $event.d;
          rdvDateF = $event.dn;
          fse = $event.f;
        "
        v-bind:beneficiaire="beneficiaire"
      />
    </template>

    <template v-if="step == 3">
      <!-- STEP 3 / VALIDATION -->
      <RdvStep3
        @previous="step = 2"
        @cancel="step = 1"
        v-bind:beneficiaire="beneficiaire"
        v-bind:rdvDate="rdvDate"
        v-bind:rdvDateF="rdvDateF"
        v-bind:fse="fse"
        v-bind:rdvToCancel="rdvToCancel"
      />
    </template>
  </div>
</template>

<script>
import { useRoute } from "vue-router";
import axios from "axios";
import RdvStep1 from "@/components/RdvStep1.vue";
import RdvStep2 from "@/components/RdvStep2.vue";
import RdvStep3 from "@/components/RdvStep3.vue";

export default {
  name: "RdvNew",
  components: {
    RdvStep1,
    RdvStep2,
    RdvStep3
  },
  data() {
    return {
      step: 1,
      beneficiaire: {
        adresse: "",
        adresses: [
          {
            address_line4_number: "",
            address_line4_name: "",
            address_line6_zipcode: "",
            address_line6_city: ""
          }
        ]
      },
      fse: {},
      rdvDate: new Date(),
      rdvDateF: new Date(),
      rdvToCancel: "",
      error: false,
      loading: false,
      rou: {}
    };
  },
  created() {
    const route = useRoute();
    this.rou = route;
    if (route.params.id) {
      axios
        .get(process.env.VUE_APP_API + "/rdv/" + route.params.id)
        .then(resp => {
          this.beneficiaire = resp.data.beneficiaire;
          this.fse = resp.data.fse;
          this.rdvDate = new Date(resp.data.date_start);
          this.rdvDate = new Date(resp.data.date_end);
          this.rdvToCancel = resp.data.uuid;
        })
        .catch(() => {
          this.$swal.fire("Une erreur s'est produite.");
        });
    }
  }
};
</script>

<style lang="scss">
.content {
  .bloc-infos {
    margin-bottom: 3rem;
    padding: 2rem 3rem;
    position: relative;

    @media screen and (min-width: 992px) {
      padding: 2rem 4rem 2rem 1rem;
    }

    .col {
      padding: 0 1rem;
    }

    .form-group {
      align-items: center;

      label {
        padding-bottom: 0;
        padding-top: 0;

        @media screen and (min-width: 992px) {
          text-align: right;
        }
      }
    }

    .btn {
      height: 4.5rem;
      padding: 1rem 2rem;
    }
  }
}
</style>
