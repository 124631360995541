<template>
  <section class="content step2">
    <h2 class="title">Agenda des rendez-vous des FSE</h2>

    <!-- <button
      type="button"
      class="btn btn-primary mb-5"
      data-toggle="modal"
      data-target="#modal-rdv"
    >
      Créer un RDV
    </button> -->

    <div class="bloc-calendar">
      <div id="calendar"></div>

      <div id="dragdrop">
        <h3>Etablissement</h3>
        <h4>LABEGE PDC1</h4>

        <h3>Zone de vacation</h3>
        <h4>{{ zoneVacation.nom }}</h4>

        <h3>FSE disponibles</h3>
        <template v-for="fse in fses" :key="fse">
          <div
            class="fse fc-event"
            :style="'background: ' + fse.couleur + ';'"
            :data-color="fse.couleur"
            v-if="getVacations(fse.vacations)"
          >
            {{ fse.prenom }} {{ fse.nom }}
          </div>
        </template>
      </div>
    </div>
    <div class="text-center but mt-5">
      <button
        type="button"
        class="btn btn-secondary"
        data-dismiss="modal"
        @click.prevent="$emit('previous')"
      >
        Retour
      </button>
    </div>
  </section>

  <!-- Modal RDV -->
  <div
    class="modal fade modal-rdv"
    id="modal-rdv"
    tabindex="-1"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ currentDateStr }}</h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Fermer"
          >
            <span aria-hidden="true"><i class="fal fa-times"></i></span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-3 d-none d-lg-block">
              <h3>FSE disponibles</h3>
            </div>
            <div class="col-12 col-md-8 text-lg-center">
              <h3>Disponibilités</h3>
            </div>
          </div>

          <!-- FSE -->
          <template v-for="fse in fses" :key="fse">
            <div
              class="row mb-4"
              v-if="
                getVacation(fse.vacations, currentDayStr) &&
                  (getVacation(fse.vacations, currentDayStr).matin?.nb ||
                    getVacation(fse.vacations, currentDayStr).aprem?.nb)
              "
            >
              <div class="col-md-3">
                <div
                  class="fse"
                  :style="'background: ' + fse.couleur + ';'"
                  :data-color="fse.couleur"
                >
                  {{ fse.prenom }} {{ fse.nom }}
                </div>
              </div>
              <div class="col-md-9">
                <table class="table-rdv">
                  <template
                    v-if="getVacation(fse.vacations, currentDayStr).matin?.nb"
                  >
                    <tr data-label="Matin">
                      <th>Matin</th>
                      <template
                        v-for="(n, i) in parseInt(
                          getVacation(fse.vacations, currentDayStr).matin?.nb
                        )"
                        :key="n"
                      >
                        <td v-if="isDispo(fse, currentDate, i, 'matin')">
                          <button
                            type="button"
                            class="btn btn-time"
                            @click="
                              addDate(
                                getVacation(fse.vacations, currentDayStr).matin,
                                i,
                                fse
                              )
                            "
                          >
                            {{
                              getTime(
                                getVacation(fse.vacations, currentDayStr).matin,
                                i
                              )
                            }}
                          </button>
                        </td>
                      </template>
                    </tr>
                  </template>
                  <template
                    v-if="getVacation(fse.vacations, currentDayStr).aprem?.nb"
                  >
                    <tr data-label="Après-midi">
                      <th>Après-midi</th>
                      <template
                        v-for="(n, i) in parseInt(
                          getVacation(fse.vacations, currentDayStr).aprem?.nb
                        )"
                        :key="n"
                      >
                        <td v-if="isDispo(fse, currentDate, i, 'aprem')">
                          <button
                            type="button"
                            class="btn btn-time"
                            @click="
                              addDate(
                                getVacation(fse.vacations, currentDayStr).aprem,
                                i,
                                fse
                              )
                            "
                          >
                            {{
                              getTime(
                                getVacation(fse.vacations, currentDayStr).aprem,
                                i
                              )
                            }}
                          </button>
                        </td>
                      </template>
                    </tr>
                  </template>
                </table>
              </div>
            </div>
          </template>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">
            Abandonner
          </button>
          <button type="button" class="btn btn-primary" @click="goNext">
            Valider
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { DateTime } from "luxon";
import { Calendar } from "@fullcalendar/core";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import interactionPlugin from "@fullcalendar/interaction";
import frLocale from "@fullcalendar/core/locales/fr";

const $ = window.$;

export default {
  name: "RdvStep2",
  props: ["beneficiaire"],
  data() {
    return {
      fses: [],
      fse: {},
      zonesVacations: [],
      zoneVacation: {
        nom: ""
      },
      currentDateStr: "",
      currentDayStr: "",
      currentDate: new Date(),
      currentDateF: new Date(),
      currentDateFn: new Date(),
      calendar: {},
      error: false,
      loading: false
    };
  },
  watch: {
    fses: {
      handler() {
        var calendarEl = document.getElementById("calendar");
        this.calendar = new Calendar(calendarEl, {
          plugins: [
            dayGridPlugin,
            timeGridPlugin,
            listPlugin,
            interactionPlugin
          ],
          initialView: "dayGridMonth",
          headerToolbar: {
            left: "prev,next today",
            center: "title",
            right: "dayGridMonth,timeGridWeek,timeGridDay"
          },
          locale: frLocale,
          editable: false,
          displayEventTime: true,
          selectable: true,
          select: info => {
            const options = {
              weekday: "long",
              year: "numeric",
              month: "long",
              day: "numeric"
            };
            this.currentDateStr = info.start.toLocaleDateString(
              "fr-FR",
              options
            );
            this.currentDateStr =
              this.currentDateStr.charAt(0).toUpperCase() +
              this.currentDateStr.slice(1);
            this.currentDate = info.start;
            const options2 = { weekday: "long" };
            this.currentDayStr = info.start.toLocaleDateString(
              "fr-FR",
              options2
            );
            if (this.hasFseDispo(info.start)) {
              $("#modal-rdv").modal();
            } else {
              this.$swal.fire("Pas de FSE disponible.", "", "info");
            }
          },
          hiddenDays: [0],
          dayCellClassNames: arg => {
            if (arg.date) {
              if (!this.hasFseDispo(arg.date)) {
                return ["disabled"];
              }
            }
            if (arg.isPast) {
              return ["disabled"];
            } else {
              return ["normal"];
            }
          },
          // timeZone: 'UTC',
          // selectConstraint: "businessHours",
          events: (info, successCallback, failureCallback) => {
            console.log(info, failureCallback);
            let events = [];
            this.fses.map(f => {
              const rdvs = [];
              f.rdvs.forEach(r => {
                if (r.etat === "O") {
                  rdvs.push({
                    title: f.nom,
                    start: r.date_start,
                    end: r.date_end,
                    color: f.couleur
                  });
                }
              });
              events = events.concat(rdvs);
            });
            successCallback(events);
          }
        });
        this.calendar.render();
      },
      deep: true
    }
  },
  computed: {
    events() {
      let events = [];
      this.fses.map(f => {
        const rdvs = [];
        f.rdvs.forEach(r => {
          if (r.etat === "O") {
            rdvs.push({
              title: f.nom,
              start: r.date_start,
              end: r.date_end,
              color: f.couleur
            });
          }
        });
        events = events.concat(rdvs);
      });
      return events;
    }
  },
  methods: {
    goNext() {
      $("#modal-rdv").modal("hide");
      this.$emit("next2", {
        d: this.currentDateF,
        dn: this.currentDateFn,
        f: this.fse
      });
    },
    addDate(target, i, fse) {
      const debut = new Date("1970-01-01 " + target.debut);
      this.currentDateF = new Date(
        this.currentDate.getTime() +
          debut.getTime() +
          parseInt(target.duree) * i * 60000 +
          3600000
      );
      this.currentDateFn = new Date(
        this.currentDate.getTime() +
          debut.getTime() +
          parseInt(target.duree) * (i + 1) * 60000 +
          3600000
      );
      this.fse = fse;
    },
    getVacation(vacations, day) {
      const v = vacations.filter(v => {
        const debut = DateTime.fromISO(v.date_start);
        const fin = DateTime.fromISO(v.date_end);
        const lday = DateTime.fromJSDate(this.currentDate);
        return (
          v.zone.id == this.zoneVacation.id && debut <= lday && lday <= fin
        );
      });
      if (v && v[0]) {
        return day && v[0].details && v[0].details[day]
          ? v[0].details[day]
          : v[0];
      } else {
        return false;
      }
    },
    getVacations(vacations, day) {
      const v = vacations.filter(v => v.zone.id == this.zoneVacation.id);
      if (v && v[0]) {
        return day && v[0].details && v[0].details[day]
          ? v[0].details[day]
          : v[0];
      } else {
        return false;
      }
    },
    getTime(target, i) {
      let nbMs = 60000;
      if (target.duree.match(/h$/g)) {
        nbMs = 3600000;
      }
      const debut = new Date("1970-01-01 " + target.debut);
      const last = new Date(
        debut.getTime() + parseInt(target.duree) * i * nbMs
      );

      const options = { hour: "2-digit", minute: "2-digit" };
      return last.toLocaleTimeString("fr-FR", options);
    },
    isDispo(fse, currentDate, i, period) {
      const currentDayStr = currentDate.toLocaleDateString("fr-FR", {
        weekday: "long"
      });
      let time;
      const vac = this.getVacation(fse.vacations, currentDayStr);
      let currentVac;

      if (period == "matin") {
        currentVac = vac.matin;
        time = this.getTime(currentVac, i);
      } else {
        currentVac = vac.aprem;
        time = this.getTime(currentVac, i);
      }

      let dispo = true;
      const debut = new Date("1970-01-01 " + time);
      const last = new Date(currentDate.getTime() + debut.getTime() + 3600000);
      let last2;
      let last3;
      if (
        this.beneficiaire.beneficiaires_enfants &&
        this.beneficiaire.beneficiaires_enfants.length
      ) {
        last2 = new Date(
          currentDate.getTime() +
            debut.getTime() +
            parseInt(currentVac.duree) * 60000 * 1 +
            3600000
        );
        if (parseInt(currentVac.nb) < 2) {
          dispo = false;
        }
        if (
          last2.toLocaleTimeString("fr-FR", {
            hour: "2-digit",
            minute: "2-digit"
          }) > currentVac.fin
        ) {
          dispo = false;
        }
        if (this.beneficiaire.beneficiaires_enfants.length == 2) {
          last3 = new Date(
            currentDate.getTime() +
              debut.getTime() +
              parseInt(currentVac.duree) * 60000 * 2 +
              3600000
          );
          if (parseInt(currentVac.nb) < 3) {
            dispo = false;
          }
          if (
            last3.toLocaleTimeString("fr-FR", {
              hour: "2-digit",
              minute: "2-digit"
            }) > currentVac.fin
          ) {
            dispo = false;
          }
        }
      }
      if (dispo) {
        fse.rdvs.forEach(r => {
          if (
            r.etat === "O" &&
            DateTime.fromISO(r.date_start).toFormat("yyyy LLL dd HH mm") ==
              DateTime.fromJSDate(last).toFormat("yyyy LLL dd HH mm")
          ) {
            dispo = false;
          }
          if (
            last2 &&
            r.etat === "O" &&
            DateTime.fromISO(r.date_start).toFormat("yyyy LLL dd HH mm") ==
              DateTime.fromJSDate(last2).toFormat("yyyy LLL dd HH mm")
          ) {
            dispo = false;
          }
          if (
            last3 &&
            r.etat === "O" &&
            DateTime.fromISO(r.date_start).toFormat("yyyy LLL dd HH mm") ==
              DateTime.fromJSDate(last3).toFormat("yyyy LLL dd HH mm")
          ) {
            dispo = false;
          }
        });
      }
      return dispo;
    },
    hasFseDispo(day) {
      let hasDispo = false;
      const lday = DateTime.fromJSDate(day);
      const wday = lday.toFormat("cccc");
      this.fses.forEach(f => {
        f.vacations.forEach(v => {
          const debut = DateTime.fromISO(v.date_start);
          const fin = DateTime.fromISO(v.date_end);
          if (
            this.zoneVacation.id == v.zone.id &&
            debut <= lday &&
            lday <= fin &&
            v.details[wday].value == true
          ) {
            hasDispo = true;
          }
          // let nbRdvs = 0;
          // f.rdvs.forEach(r => {
          //   if (
          //     r.etat === "O" &&
          //     DateTime.fromISO(r.date_start).toFormat("yyyy LLL dd") ==
          //       lday.toFormat("yyyy LLL dd")
          //   ) {
          //     nbRdvs++;
          //   }
          // });
          // const nbMatin = v.details[wday].matin.nb
          //   ? v.details[wday].matin.nb
          //   : 0;
          // const nbAprem = v.details[wday].aprem.nb
          //   ? v.details[wday].aprem.nb
          //   : 0;
          // if (nbRdvs > 0 && nbRdvs == parseInt(nbMatin) + parseInt(nbAprem)) {
          //   hasDispo = false;
          // }
        });
      });
      return hasDispo;
    }
  },
  created() {
    axios
      .all([
        axios.get(process.env.VUE_APP_API + "/fse/list"),
        axios.get(process.env.VUE_APP_API + "/zone-vacation/list")
      ])
      .then(
        axios.spread((...responses) => {
          this.fses = responses[0].data;
          this.zonesVacations = responses[1].data;

          const zv = this.zonesVacations.filter(z => {
            let b = false;
            z.communes.forEach(c => {
              if (c.cp == this.beneficiaire.adresses[0].address_line6_zipcode) {
                b = true;
              }
            });
            return b;
          });
          this.zoneVacation = zv && zv[0] ? zv[0] : { nom: "" };
        })
      )
      .catch(() => {
        this.$swal.fire("Une erreur s'est produite.");
      });
  }
};
</script>

<style lang="scss">
.fc-daygrid-event-harness,
.fc-timegrid-event-harness {
  cursor: pointer;
}
.fc td.fc-day-sat,
.fc td.fc-day-sun,
.fc td.fc-sat {
  background-color: rgb(255, 255, 255);
}
.disabled {
  background-color: rgba(221, 221, 221, 0.7) !important;
}
</style>

<style scoped lang="scss">
.content {
  .bloc-calendar {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    flex-wrap: wrap;

    @media screen and (min-width: 992px) {
      flex-direction: row;
    }

    #calendar {
      order: 2;

      @media screen and (min-width: 992px) {
        order: 1;
        min-width: 75%;
        flex-basis: 75%;
        padding-right: 6rem;
      }
    }

    #dragdrop {
      margin-bottom: 2rem;
      order: 1;

      @media screen and (min-width: 992px) {
        min-width: 25%;
        flex-basis: 25%;
        margin-bottom: 0;
        order: 2;
      }

      h3 {
        margin: 0 0 1rem;
      }

      h4 {
        background: var(--navy);
        border-radius: 0.5rem;
        color: white;
        font-size: 1.4rem;
        margin: 0 0 3rem;
        padding: 0.5rem 1rem;
        text-transform: uppercase;
      }

      // .fse {
      //   cursor: move;
      // }
    }
  }
}
</style>
