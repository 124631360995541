<template>
  <section class="content step1">
    <template v-if="!rou.params.id">
      <h2 class="title">Rechercher un bénéficiaire</h2>

      <!-- SEARCH -->
      <div class="bloc-search bloc-shadow">
        <form>
          <div class="row">
            <div class="col col-12 col-md-9">
              <div class="form-group">
                <input
                  type="text"
                  class="form-control"
                  name="beneficiaire"
                  id="beneficiaire"
                  placeholder="Ex : Martine PICARD"
                />
              </div>
            </div>
            <!-- <div class="col col-12 col-md-3 text-right">
              <button type="submit" class="btn btn-primary">Rechercher</button>
            </div> -->
          </div>
        </form>
      </div>

      <!-- TABLE -->
      <table
        ref="table"
        id="table-rdv"
        class="table table-striped bloc-shadow display responsive nowrap"
      >
        <thead>
          <tr>
            <th></th>
            <th>Nom</th>
            <th>Prénom</th>
            <th>Code Postal et Commune</th>
            <th>Référence</th>
          </tr>
        </thead>
        <tbody></tbody>
      </table>
    </template>
    <div id="infos"></div>

    <h2 class="title" v-show="beneficiaire.uuid">
      Informations du bénéficiaire
    </h2>

    <!-- INFOS -->
    <form @submit.prevent="goNext" v-show="beneficiaire.uuid">
      <div class="bloc-infos bloc-shadow">
        <div class="form-group row">
          <label for="adresse" class="col col-12 col-md-3">Adresse*</label>
          <div class="col col-12 col-md-6">
            <Address
              ref="ad"
              @input="suggestAddress($event)"
              @result="setAdresse($event)"
              :items="adresses"
              :def="beneficiaire.adresse"
              :isAsync="true"
              @update:def="log($event)"
            />
          </div>
          <div class="col col-md-3 mt-3 mt-lg-0 text-right">
            <button
              type="submit"
              class="btn btn-primary"
              @click.prevent="checkAddress"
            >
              Tester l'adresse
            </button>
          </div>
        </div>
        <div class="form-group row">
          <label for="civilite" class="col col-12 col-md-3">Civilité</label>
          <div class="col col-12 col-md-3">
            <select
              class="custom-select"
              name="civilite"
              v-model="beneficiaire.civilite"
            >
              <option selected>Civilité</option>
              <option value="mme">Mme</option>
              <option value="m">Mr</option>
            </select>
          </div>
        </div>
        <div class="form-group row">
          <label for="nom" class="col col-12 col-md-3">Prénom*</label>
          <div class="col col-12 col-md-6">
            <input
              type="text"
              class="form-control"
              name="nom"
              placeholder="Votre prénom"
              v-model="beneficiaire.prenom"
            />
          </div>
        </div>
        <div class="form-group row">
          <label for="nom" class="col col-12 col-md-3">Nom*</label>
          <div class="col col-12 col-md-6">
            <input
              type="text"
              class="form-control"
              name="nom"
              placeholder="Votre nom"
              v-model="beneficiaire.nom"
            />
          </div>
        </div>
        <div class="form-group row">
          <label for="reference" class="col col-12 col-md-3">
            Référence bénéficiaire
          </label>
          <div class="col col-12 col-md-6">
            <input
              type="text"
              class="form-control"
              name="reference"
              placeholder="Ex : 045658912-2"
              v-model="beneficiaire.reference"
              readonly
            />
          </div>
        </div>
        <div class="form-group row">
          <div class="col col-12 col-md-6 offset-md-3">
            <button
              type="button"
              class="btn btn-primary"
              @click="addEnfant"
              v-if="
                beneficiaire.beneficiaires_enfants &&
                  beneficiaire.beneficiaires_enfants.length < 2
              "
            >
              Ajouter un bénéficiaire associé
            </button>
          </div>
        </div>

        <!-- CHAMPS NOUVEAU BENEFICIAIRE / EN DISPLAY:NONE -->
        <div
          class="new-benef"
          v-for="enfant in beneficiaire.beneficiaires_enfants"
          :key="enfant.id"
        >
          <div class="form-group row">
            <label for="civilite" class="col col-12 col-md-3">Civilité</label>
            <div class="col col-12 col-md-3">
              <select
                class="custom-select"
                name="civilite"
                v-model="enfant.civilite"
              >
                <option value="mme">Mme</option>
                <option value="m">Mr</option>
              </select>
            </div>
          </div>
          <div class="form-group row">
            <label for="nom" class="col col-12 col-md-3">Prénom*</label>
            <div class="col col-12 col-md-6">
              <input
                type="text"
                class="form-control"
                name="nom"
                placeholder="Votre prénom"
                v-model="enfant.prenom"
              />
            </div>
          </div>
          <div class="form-group row">
            <label for="nom" class="col col-12 col-md-3">Nom*</label>
            <div class="col col-12 col-md-6">
              <input
                type="text"
                class="form-control"
                name="nom"
                placeholder="Votre nom"
                v-model="enfant.nom"
              />
            </div>
          </div>
          <div class="form-group row">
            <label for="reference" class="col col-12 col-md-3">
              Référence bénéficiaire
            </label>
            <div class="col col-12 col-md-6">
              <input
                type="text"
                class="form-control"
                name="reference"
                placeholder="Ex : 045658912-2"
                v-model="enfant.reference"
                readonly
              />
            </div>
          </div>
        </div>
        <!-- /END CHAMPS NOUVEAU BENEFICIAIRE -->

        <div class="form-group row">
          <label for="tel-mobile" class="col col-12 col-md-3">
            Téléphone mobile<br /><i
              >(Obligatoire pour<br />les envois de SMS)</i
            >
          </label>
          <div class="col col-12 col-md-6">
            <input
              type="text"
              class="form-control"
              name="tel-mobile"
              placeholder="Ex : 06 12 34 56 78"
              v-model="beneficiaire.telephone_mobile"
              size="14"
              maxlength="14"
              @keyup="autoTelMobile"
            />
          </div>
        </div>
        <div class="form-group row">
          <label for="tel-fixe" class="col col-12 col-md-3">
            Téléphone fixe
          </label>
          <div class="col col-12 col-md-6">
            <input
              type="text"
              class="form-control"
              name="tel-fixe"
              placeholder="Ex : 01 23 45 67 89"
              v-model="beneficiaire.telephone_fixe"
              size="14"
              maxlength="14"
              @keyup="autoTelFixe"
            />
          </div>
        </div>
        <div class="form-group row">
          <label for="adresse-plus" class="col col-12 col-md-3">
            Complément adresse
          </label>
          <div class="col col-12 col-md-6">
            <input
              type="text"
              class="form-control"
              name="adresse-plus"
              placeholder="Ex : Appt B2, porte 4, code 1234..."
            />
          </div>
        </div>
        <div class="form-group row">
          <label for="email" class="col col-12 col-md-3">Email</label>
          <div class="col col-12 col-md-6">
            <input
              type="email"
              class="form-control"
              name="email"
              placeholder="xxx@yyy.com"
              v-model="beneficiaire.email"
            />
          </div>
        </div>
        <div class="form-group row">
          <label for="date-naissance" class="col col-12 col-md-3">
            Date de naissance
          </label>
          <div class="col col-12 col-md-6">
            <input
              type="text"
              class="form-control"
              name="date-naissance"
              placeholder="Ex : 09/03/1977"
              v-model="birthday"
              size="10"
              maxlength="10"
              @keyup="autoDate"
            />
          </div>
        </div>
        <div class="form-group row">
          <label for="commentaire" class="col col-12 col-md-3">
            Commentaire
          </label>
          <div class="col col-12 col-md-6">
            <textarea
              name="commentaire"
              rows="2"
              class="form-control"
              placeholder="Ex : Appelez-moi avant d'arriver. Merci."
              v-model="beneficiaire.commentaire"
            ></textarea>
          </div>
        </div>
      </div>

      <p class="pl-5"><small>* Informations obligatoires</small></p>
      <div class="text-center">
        <button
          type="reset"
          class="btn btn-secondary mx-3"
          @click.prevent="$router.push('liste-rdv')"
        >
          Abandonner
        </button>
        <button type="submit" class="btn btn-primary mx-3">Valider</button>
      </div>
    </form>
  </section>
</template>

<script>
import { useRoute } from "vue-router";
import axios from "axios";
import Address from "@/components/Address.vue";
import { DateTime, Settings } from "luxon";

Settings.defaultLocale = "fr";

const $ = window.$;

export default {
  name: "RdvStep1",
  props: ["beneficiairev"],
  components: {
    Address
  },
  computed: {
    birthday: {
      get() {
        if (!this.beneficiaire.date_naissance) {
          return "";
        }
        if (
          this.beneficiaire.date_naissance.match(/^(\d\d\d\d-\d\d-\d\d)/g) &&
          DateTime.fromISO(this.beneficiaire.date_naissance).isValid
        ) {
          return DateTime.fromISO(this.beneficiaire.date_naissance)
            .setLocale("fr")
            .toLocaleString(DateTime.DATE_SHORT);
        }
        return this.beneficiaire.date_naissance;
      },
      set(newValue) {
        if (
          newValue.match(/^(\d\d\/\d\d\/\d\d\d\d)$/g) &&
          DateTime.fromFormat(newValue, "dd/MM/yyyy").isValid
        ) {
          this.beneficiaire.date_naissance = DateTime.fromFormat(
            newValue,
            "dd/MM/yyyy"
          ).toISO();
        } else {
          this.beneficiaire.date_naissance = newValue;
        }
      }
    }
  },
  data() {
    return {
      fses: [],
      zonesVacations: [],
      adresses: [],
      adressesFull: [],
      beneficiaires: [],
      beneficiaire: {
        adresse: "",
        adresses: [
          {
            address_line4_number: "",
            address_line4_name: "",
            address_line6_zipcode: "",
            address_line6_city: ""
          }
        ],
        beneficiaires_enfants: [
          {
            civilite: "",
            nom: "",
            prenom: "",
            reference: ""
          }
        ]
      },
      settings: {},
      newBenef: false,
      error: false,
      loading: false,
      rou: {}
    };
  },
  methods: {
    addEnfant() {
      if (this.beneficiaire.beneficiaires_enfants.length < 2) {
        const enfant = {
          civilite: "",
          nom: "",
          prenom: "",
          reference:
            this.beneficiaire.reference +
            "-" +
            (this.beneficiaire.beneficiaires_enfants.length + 2),
          adresse: this.beneficiaire.adresse,
          adresses: JSON.parse(JSON.stringify(this.beneficiaire.adresses)),
          email: this.beneficiaire.email,
          telephone_mobile: this.beneficiaire.telephone_mobile,
          telephone_fixe: this.beneficiaire.telephone_fixe
        };
        enfant.adresses.forEach(a => {
          delete a.id;
          delete a.uuid;
        });
        this.beneficiaire.beneficiaires_enfants.push(enfant);
      }
    },
    goNext() {
      // this.loading = true;
      this.error = false;
      delete this.beneficiaire.rdvs;
      let msg = "";

      if (
        this.birthday &&
        !DateTime.fromFormat(this.birthday, "dd/L/yyyy").isValid
      ) {
        this.error = true;
        msg += "La date de naissance est invalide" + "\n";
      }

      if (
        this.birthday &&
        DateTime.fromFormat(this.birthday, "dd/L/yyyy") > DateTime.now()
      ) {
        this.error = true;
        msg += "La date de naissance est dans le futur" + "\n";
      }

      if (!this.beneficiaire.nom.length) {
        this.error = true;
        msg += "Le nom est obligatoire" + "\n";
      }

      if (!this.beneficiaire.prenom.length) {
        this.error = true;
        msg += "Le prénom est obligatoire" + "\n";
      }

      if (
        this.beneficiaire.beneficiaires_enfants &&
        this.beneficiaire.beneficiaires_enfants.length
      ) {
        this.beneficiaire.beneficiaires_enfants.forEach(b => {
          if (!b.nom) {
            this.error = true;
            msg += "Le nom du bénéficiaire associé est obligatoire" + "\n";
          }
          if (!b.prenom) {
            this.error = true;
            msg += "Le prénom du bénéficiaire associé est obligatoire" + "\n";
          }
        });
      }

      if (this.error) {
        this.$swal.fire(msg);
      } else {
        this.$emit("next", this.beneficiaire);
      }
    },
    setAdresse(i) {
      const adresse = this.adressesFull[i].properties;
      this.beneficiaire.adresses[0].address_line4_number = adresse.housenumber;
      this.beneficiaire.adresses[0].address_line4_name = adresse.street;
      this.beneficiaire.adresses[0].address_line6_zipcode = adresse.postcode;
      this.beneficiaire.adresses[0].address_line6_city = adresse.city;
      this.beneficiaire.adresse = adresse.label;
    },
    log(data) {
      this.beneficiaire.adresse = data;
    },
    autoDate(data) {
      this.birthday = data.target.value
        .replace(/^(\d\d)(\d)$/g, "$1/$2")
        .replace(/^(\d\d\/\d\d)(\d+)$/g, "$1/$2")
        .replace(/[^\d/]/g, "");
    },
    autoTelMobile(data) {
      this.beneficiaire.telephone_mobile = data.target.value
        .replace(/^(\d\d)(\d)$/g, "$1 $2")
        .replace(/^(\d\d\s\d\d)(\d+)$/g, "$1 $2")
        .replace(/^(\d\d\s\d\d\s\d\d)(\d+)$/g, "$1 $2")
        .replace(/^(\d\d\s\d\d\s\d\d\s\d\d)(\d+)$/g, "$1 $2")
        .replace(/[^\d\s]/g, "");
    },
    autoTelFixe(data) {
      this.beneficiaire.telephone_fixe = data.target.value
        .replace(/^(\d\d)(\d)$/g, "$1 $2")
        .replace(/^(\d\d\s\d\d)(\d+)$/g, "$1 $2")
        .replace(/^(\d\d\s\d\d\s\d\d)(\d+)$/g, "$1 $2")
        .replace(/^(\d\d\s\d\d\s\d\d\s\d\d)(\d+)$/g, "$1 $2")
        .replace(/[^\d\s]/g, "");
    },
    suggestAddress: function(adresse) {
      this.loading = true;
      this.error = false;

      if (typeof adresse === "string") {
        axios
          .post(process.env.VUE_APP_API + "/adresse/suggest", { adresse })
          .then(resp => {
            this.adresses = resp.data.map(a => a.properties.label);
            this.adressesFull = resp.data;
          })
          .catch(() => {
            this.$swal.fire("Une erreur s'est produite.");
          });
      }
    },
    checkAddress: function() {
      this.loading = true;
      this.error = false;

      const adresse = this.$refs.ad.search;

      axios
        .post(process.env.VUE_APP_API + "/adresse/test", { adresse })
        .then(resp => {
          const cp = resp.data.postal_code;
          const ville = resp.data.city;
          const num = resp.data.number;
          const street = resp.data.street;
          let b = false;
          this.zonesVacations.filter(z => {
            z.communes.forEach(c => {
              if (c.cp == cp) {
                b = true;
              }
            });
            return b;
          });
          if (b) {
            this.$swal.fire("Adresse éligible");
            this.beneficiaire.adresses[0].address_line4_number = num;
            this.beneficiaire.adresses[0].address_line4_name = street;
            this.beneficiaire.adresses[0].address_line6_zipcode = cp;
            this.beneficiaire.adresses[0].address_line6_city = ville;
          } else {
            this.$swal.fire(
              "La ville " +
                cp +
                " " +
                ville +
                " n'est rattachée à aucune zone de vacation de l'établissement."
            );
          }
        })
        .catch(() => {
          this.$swal.fire("Une erreur s'est produite.");
        });
    }
  },
  created() {
    const route = useRoute();
    this.rou = route;
    axios
      .get(process.env.VUE_APP_API + "/zone-vacation/list")
      .then(resp => {
        this.zonesVacations = resp.data;
      })
      .catch(() => {
        this.$swal.fire("Une erreur s'est produite.");
      });
  },
  watch: {
    beneficiairev: function() {
      if (this.beneficiairev) {
        this.beneficiaire = this.beneficiairev;
      }
    }
  },
  mounted() {
    const dataTable = $("#table-rdv").DataTable({
      dom: "<<t>r<lip>>",
      columnDefs: [
        {
          orderable: false,
          targets: 1
        }
      ],
      ajax: {
        url: process.env.VUE_APP_API + "/beneficiaire/search",
        dataSrc: json => {
          this.beneficiaires = json.data;
          return json.data;
        },
        headers: {
          Authorization: "Bearer " + localStorage.getItem("user-token")
        }
      },
      initComplete: (settings, json) => {
        this.settings = settings;
        this.beneficiaires = json.data;
      },
      processing: true,
      serverSide: true,
      columns: [
        {
          data: "uuid",
          render: data =>
            '<a href="#" class="edit-placeholder" id="' +
            data +
            '"><i class="fal fa-eye" aria-hidden="true"></i></a>'
        },
        {
          data: "nom",
          className: "text-uppercase"
        },
        {
          data: "prenom"
        },
        {
          data: "adresse",
          className: "text-uppercase"
        },
        {
          data: "reference"
        }
      ],
      order: [[1, "asc"]],
      pageLength: 10,
      language: {
        lengthMenu: "Par page : _MENU_",
        zeroRecords: "Aucun résultat",
        processing: "Chargement en cours...",
        paginate: {
          first: "Premier",
          last: "Dernier",
          next: "Suivant",
          previous: "Précédent"
        }
      },
      responsive: true
    });
    $(".dataTables_length select").addClass("custom-select");
    $("#beneficiaire").keyup(() => {
      dataTable.search($("#beneficiaire").val()).draw();
    });

    const self = this;
    $("tbody", this.$refs.table).on("click", ".edit-placeholder", function(e) {
      e.preventDefault();
      const b = self.beneficiaires.filter(t => t.uuid == $(this).attr("id"));
      if (b) {
        self.beneficiaire = b[0];
        if (!self.beneficiaire.beneficiaires_enfants.length) {
          // self.beneficiaire.beneficiaires_enfants.push({
          //   civilite: "",
          //   nom: "",
          //   prenom: "",
          //   reference: ""
          // });
        }
        $([document.documentElement, document.body]).animate(
          {
            scrollTop: $("#infos").offset().top - 100
          },
          1000
        );
      }
    });
  }
};
</script>

<style scoped lang="scss">
div.dataTables_processing {
  z-index: 1;
}
</style>
