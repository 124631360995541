<template>
  <section class="content step1">
    <h2 class="title">Le rendez-vous avec un FSE pour le bénéficiaire</h2>

    <!-- BENEFICIAIRE -->
    <div class="bloc-shadow">
      <h3>{{ civilite }} {{ beneficiaire.prenom }} {{ beneficiaire.nom }}</h3>
      Adresse :
      <strong>{{ beneficiaire.adresse }}</strong>
      <br />
      Tél :
      <strong>{{ beneficiaire.telephone_mobile }}</strong>
      <br />
      Email :
      <strong>{{ beneficiaire.email }}</strong>
      <br />
      Référence bénéficiaire :
      <strong>{{ beneficiaire.reference }}</strong>
    </div>

    <h2 class="title">Avec le Facteur Service Expert</h2>

    <!-- FSE -->
    <div class="bloc-fse bloc-shadow">
      <div class="infos">
        <img src="@/assets/img/icon-fse.png" alt="FSE" />
        <div>
          <h3>{{ fse.prenom }} {{ fse.nom }}</h3>
          Etablissement : <strong>LABEGE PDC1</strong><br />
          Zone : <strong>{{ zoneVacation }}</strong>
        </div>
      </div>
      <div class="infos">
        <img src="@/assets/img/icon-calendar.png" alt="Date du rdv" />
        <div>
          <h3>
            {{ dateStr }} <br />
            de {{ startTimeStr }} à {{ endTimeStr }}
          </h3>
        </div>
      </div>
      <div class="edit">
        <button
          type="button"
          class="btn btn-primary"
          @click.prevent="$emit('previous')"
        >
          Modifier le RDV
        </button>
      </div>
    </div>

    <!-- BUTTONS -->
    <div class="text-center">
      <button
        type="reset"
        class="btn btn-secondary mx-3"
        @click.prevent="$emit('cancel')"
      >
        Abandonner
      </button>
      <button
        type="submit"
        class="btn btn-primary mx-3"
        @click.prevent="saveRdv"
      >
        Valider
      </button>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import { DateTime, Settings } from "luxon";

Settings.defaultLocale = "fr";

export default {
  name: "RdvStep3",
  props: ["beneficiaire", "rdvDate", "rdvDateF", "fse", "rdvToCancel"],
  data() {
    return {
      zonesVacations: [],
      error: false,
      loading: false
    };
  },
  computed: {
    civilite() {
      return (
        this.beneficiaire.civilite.charAt(0).toUpperCase() +
        this.beneficiaire.civilite.slice(1)
      );
    },
    dateStr() {
      const options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric"
      };
      let dateStr = this.rdvDate.toLocaleDateString("fr-FR", options);
      dateStr = dateStr.charAt(0).toUpperCase() + dateStr.slice(1);
      return dateStr;
    },
    startTimeStr() {
      const options = { hour: "2-digit", minute: "2-digit" };
      return this.rdvDate.toLocaleTimeString("fr-FR", options);
    },
    endTimeStr() {
      const options = { hour: "2-digit", minute: "2-digit" };
      return new Date(this.rdvDateF.getTime()).toLocaleTimeString(
        "fr-FR",
        options
      );
    },
    zoneVacation() {
      const zv = this.zonesVacations.filter(z => {
        let b = false;
        z.communes.forEach(c => {
          if (c.cp == this.beneficiaire.adresses[0].address_line6_zipcode) {
            b = true;
          }
        });
        return b;
      });
      return zv && zv[0] ? zv[0].nom : "";
    }
  },
  created() {
    axios
      .get(process.env.VUE_APP_API + "/zone-vacation/list")
      .then(resp => {
        this.zonesVacations = resp.data;
      })
      .catch(() => {
        this.$swal.fire("Une erreur s'est produite.");
      });
  },
  methods: {
    saveRdv() {
      let benes;
      if (
        this.beneficiaire &&
        this.beneficiaire.beneficiaires_enfants &&
        this.beneficiaire.beneficiaires_enfants.length
      ) {
        benes = JSON.parse(
          JSON.stringify(this.beneficiaire.beneficiaires_enfants)
        );
        delete this.beneficiaire.beneficiaires_enfants;
      }
      if (
        this.beneficiaire &&
        this.beneficiaire.date_naissance &&
        !this.beneficiaire.date_naissance.length
      ) {
        delete this.beneficiaire.date_naissance;
      }
      axios
        .post(process.env.VUE_APP_API + "/rdv/save", {
          // date_start: new Date(this.rdvDate.getTime() + 7200000).toISOString(),
          // date_end: new Date(this.rdvDateF.getTime() + 7200000).toISOString(),
          date_start: DateTime.fromJSDate(this.rdvDate).toISO(),
          date_end: DateTime.fromJSDate(this.rdvDateF).toISO(),
          beneficiaire: this.beneficiaire,
          fse: {
            id: this.fse.id
          },
          cancel: this.rdvToCancel
        })
        .then(() => {
          this.showAlert("Le rendez-vous a bien été enregistré.");
        })
        .catch(() => {
          this.$swal.fire("Une erreur s'est produite.");
        });
      if (benes) {
        let i = 0;
        benes.forEach(b => {
          b.beneficiaire_parent = { id: this.beneficiaire.id };
          console.log(DateTime.fromJSDate(this.rdvDateF).diff(DateTime.fromJSDate(this.rdvDate), ["minutes"]))
          const duree = DateTime.fromJSDate(this.rdvDateF).diff(DateTime.fromJSDate(this.rdvDate), ["minutes"]);
          console.log(duree.values.minutes)
          axios
            .post(process.env.VUE_APP_API + "/rdv/save", {
              date_start: DateTime.fromJSDate(this.rdvDateF).plus({minutes: duree.values.minutes * i}).toISO(),
              date_end: DateTime.fromJSDate(this.rdvDateF).plus({minutes: duree.values.minutes * ++i}).toISO(),
              beneficiaire: b,
              fse: {
                id: this.fse.id
              },
              cancel: this.rdvToCancel
            })
            .then(() => {
              this.showAlert("Le rendez-vous a bien été enregistré.");
            })
            .catch(() => {
              this.$swal.fire("Une erreur s'est produite.");
            });
        });
      }
    },
    showAlert(txt) {
      this.$swal.fire(txt).then(() => this.$router.push("/liste-rdv"));
    }
  }
};
</script>

<style scoped lang="scss">
.bloc-shadow {
  margin-bottom: 3rem;
  padding: 2rem;

  h3 {
    margin-bottom: 0;
  }
}

.bloc-fse {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 7rem;

  .infos {
    display: flex;
    align-items: flex-start;
    flex: 1 1 auto !important;

    &:first-child {
      padding-bottom: 2rem;

      @media screen and (min-width: 992px) {
        min-width: 55%;
        flex-basis: 55%;
        padding-bottom: 0;
        padding-right: 3rem;
      }
    }

    &:last-child {
      @media screen and (min-width: 992px) {
        min-width: 45%;
        flex-basis: 45%;
      }
    }

    img {
      margin-right: 2.5rem;
    }

    div {
      padding-top: 1rem;
    }
  }

  .edit {
    margin-top: 3rem;
    text-align: center;
    width: 100%;
  }
}
</style>
